(function ($) {
  Drupal.behaviors.offerPromoV1 = {
    attach: function (context, settings) {
      $(document).ready(function () {
      // Redirect to offers page on clicking the DIV
        $('#header').on('.offer-promo-row', 'click', function (e) {
          e.preventDefault();
          var linkElement = $(this).find('.cta a');

          window.location.href = linkElement[0].href;
        });
      });
    }
  };
})(jQuery);
